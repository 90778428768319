<template>
  <div class="adxcost">
    <div class="adxcost_select">
      <a-range-picker
        class="search_item"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select-more
        class="search_item"
        v-model="searchquery.platIdList"
        :allData="platList"
        :searchById="true"
        :hasIcon="true"
        :showId="false"
        width="450px"
        label="广告平台"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.platAccountIdList"
        :allData="platAccountList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="已授权账户"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.dspPlaceIdList"
        :allData="dspPlaceList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="已授权广告源"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.positionIdList"
        :allData="styleList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="mediatom样式"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
      />
      <a-button type="primary" @click="handleAutoSearch">查询</a-button>
    </div>

    <div class="adxcost_middle">
      <div class="middle_con">
        <div class="con_pay">
          <h3>总充值金额：</h3>
          <span>{{ numFormat(totalAmount || 0, 0) }}元</span>
        </div>
        <div class="con_pay">
          <h3>剩余可用金额：</h3>
          <span>{{ numFormat(balance || 0, 0) }}元</span>
        </div>
      </div>
      <a-button type="primary" @click="lookRecords">查看充值记录</a-button>
    </div>

    <!-- 表格 -->
    <div class="adxcost_table m-t-10">
      <div class="table_opr">
        <div class="dimension_list">
          <a-button type="link" @click="Visible = true"><a-icon type="radar-chart"/> 维度</a-button>
          <div class="dimension_item" v-for="(item, index) in baseColumns" :key="item.prop">
            <span>{{ item.label }}</span>
            <span class="dimension_close" @click="handleCloseDimensionItem(index)"><a-icon type="close" /></span>
          </div>
        </div>
        <a-button type="link" @click="handleClickCustTagsBtn"><a-icon type="edit" />自定义指标</a-button>
      </div>
      <el-table
        :loading="loadings"
        :key="tableKey"
        border
        fit
        :data="tableData"
        @sort-change="handleSort"
      >
        <el-table-column
          align="left"
          v-for="item in columns"
          :key="item.prop"
          :width="columns.length > 10 ? item.width : undefined"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :sortable="item.sorter ? 'custom' : undefined"
        >
          <template slot="header">
            <m-tips v-if="!isShowDesc(item.prop)" :showIcon="false" :content="item.desc" :title="item.label" :width="150"></m-tips>
          </template>
          <template slot-scope="{ row }">
            <template v-if="isRota(item.prop)"> {{ row[item.prop] }}% </template>
            <template v-else-if="row.type === 'sum' && item.prop === 'dspPlaceName'">--</template>
            <template v-else-if="isNumFormat(item.prop)"> {{ numFormat(row[item.prop] || 0, 0) }} </template>
            <template v-else> {{ row[item.prop] }} </template>
          </template>
        </el-table-column>
      </el-table>
      <m-empty v-if="tableData.length <= 1" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
      <a-pagination
        v-if="tableData.length > 1"
        class="pagination"
        show-size-changer
        :current="query.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
      />
    </div>
    <!-- 维度 -->
    <CustDimension
      v-if="Visible"
      :visible="Visible"
      :allDimesion="allDimesion"
      :groupByList="groupByList"
      @modalCancel="Visible = false"
      @changeCustDimension="changeCustDimension"
    />
    <!-- 充值记录 -->
    <RechargeModal
      v-if="rechargeVisible"
      @modalCancel="rechargeVisible = false"
      :visible="rechargeVisible"/>

    <!-- 自定义指标 -->
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :storageName="storageName"
      :defaultCheckedList="defaultCheckedList"
    />
  </div>
</template>
<script>
import {
  adxPlatList, // 授权广告平台 -下拉框
  adxPlatAccountList, // 授权广告平台账户-下拉框
  dspPlaceList, // 授权广告源-下拉框
  adxCostpager,
  getAmountInfo
} from '@/api/reportdatas'
import mixDate from '@/mixins/initDate'
import CustDimension from '../SynthReport/SynthTable/CustDimension'
import CustTagsModal from '@/components/common/CustTagsModal'
import CustomListModal from '@/components/common/CustomList'
import RechargeModal from './components/rechargeModal'
import { getDuration } from '@/utils/duration'
import localDataName from '@/utils/localDataName'
import custTagObj from './init'
import { numFormat } from '@/utils/dealNumber'

export default {
  name: 'Alliance',
  components: { CustomListModal, RechargeModal, CustDimension, CustTagsModal },
  mixins: [mixDate],
  data () {
    return {
      searchquery: {
        positionIdList: [],
        platIdList: [],
        platAccountIdList: [],
        dspPlaceIdList: [],
        groupByList: ['date'],
        sortBy: '',
        sortOrder: ''
      },
      query: {
        page: 1,
        limit: 10
      },
      defaultCheckedList: [],
      sureArr: [],
      dspPlaceList: [],
      platList: [],
      platAccountList: [],
      styleList: [
        { id: '1', name: '开屏' },
        { id: '2', name: '插屏' },
        { id: '3', name: '激励视频' },
        { id: '4', name: '信息流' }
      ],
      totalAmount: null,
      balance: null,
      rechargeVisible: false,
      Visible: false,
      groupByList: [], // 当前维度列表
      baseColumns: [],
      dateColumn: [
        {
          label: '日期',
          key: 'date',
          prop: 'date',
          width: 160,
          align: 'left',
          fixed: true
        }
      ],
      allDimesion: [
        {
          key: 'plat_id',
          name: '广告平台',
          checked: false,
          prop: 'platName',
          width: 180
        },
        {
          key: 'plat_account_id',
          name: '广告平台账号',
          checked: false,
          prop: 'platAccountName',
          width: 180
        },
        {
          key: 'dsp_place_id',
          name: '广告源',
          checked: false,
          prop: 'dspPlaceName',
          width: 160
        },
        {
          key: 'position_id',
          name: '广告样式',
          checked: false,
          prop: 'positionName',
          width: 140
        }
      ],
      // 缓存名
      storageName: localDataName.adxcost, // 本地缓存key
      // 总指标
      targetArr: [],
      loadings: false,
      tableData: [], // list data
      total: 0,
      rangePickerDate: null, // 日期
      custTagsVisible: false,
      columns: [],
      weiArr: [],
      tableKey: ''
    }
  },
  watch: {
    $route: {
      handler (val) {
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.getTableList()
    this.getPlatList()
    this.getAdxPlatAccountList()
    this.getDspPlaceList()
    this.getAmountInfo()
    this.initTargetArr()
  },
  methods: {
    numFormat,
    // 排序
    handleSort (column) {
      const { prop, order } = column
      const sortList = {
        advRequest: 1,
        advRequestFilledRate: 2,
        impress: 3,
        impressRate: 4,
        cost: 5,
        clickRate: 6,
        askPrice: 7,
        askPriceFilledRate: 8,
        click: 9,
        impressRequestRate: 10,
        advPrice: 11,
        estimatedRevenueEcpm: 12
      }
      const status = {
        null: '',
        descending: 'desc', // 降序
        ascending: 'asc' // 升序
      }
      this.searchquery.sortOrder = status[order]
      this.searchquery.sortBy = sortList[prop]
      this.getTableList()
    },
    handleCloseDimensionItem (index) {
      this.weiArr.splice(index, 1)
      this.changeCustDimension(this.weiArr)
    },
    isNumFormat (prop) {
      const rotaPropList = [
        'advRequest',
        'advResponse',
        'impress',
        'cost',
        'askPriceRequest',
        'askPrice',
        'click',
        'cpc',
        'advPrice',
        'estimatedRevenueEcpm',
        'advPriceCpc'
      ]
      return rotaPropList.includes(prop)
    },
     // 是否是百分比
    isRota (prop) {
      const rotaPropList = [
        'advRequestFilledRate',
        'impressRate',
        'clickRate',
        'askPriceFilledRate',
        'bidSuccessRate',
        'impressRequestRate',
        'clickGap',
        'impressionGap',
        'profitRate'
      ]
      return rotaPropList.includes(prop)
    },
    isShowDesc (prop) {
      const rotaPropList = [
        'date',
        'dspPlaceName',
        'platName',
        'positionName',
        'platAccountName'
      ]
      return rotaPropList.includes(prop)
    },
    // 自定义指标弹窗成功回调
    changeCustTags (targetArr, sureArr) {
      this.tableKey = '' + new Date().getTime()
      this.targetArr = targetArr
      this.sureArr = sureArr
      this.columns = [ ...this.dateColumn, ...this.baseColumns, ...this.sureArr ]
    },
    // 初始化自定义指标
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.targetArr = JSON.parse(JSON.stringify(targetArr))
      this.defaultCheckedList = JSON.parse(JSON.stringify(sureArr))
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
      this.columns = [...this.dateColumn, ...this.sureArr]
    },
    // 修改维度后，获取数据
    changeCustDimension (arr, isskip) {
      // 当前维度列表
      const arr1 = ['date']
      this.searchquery.groupByList = [...arr1, ...arr.map((item) => item.key)]
      const dimensions = arr.map((item) => {
        return {
          label: item.name,
          prop: item.prop,
          width: item.width,
          align: 'left'
        }
      })
      this.baseColumns = dimensions
      this.weiArr = arr
      this.columns = [...this.dateColumn, ...this.baseColumns, ...this.sureArr]
      !isskip && this.getTableList()
    },
    async getPlatList () {
      const resp = await adxPlatList()
      if (resp.code === 200) this.platList = resp.data || []
    },
    async getAdxPlatAccountList () {
      const resp = await adxPlatAccountList()
      if (resp.code === 200) this.platAccountList = resp.data || []
    },
    async getDspPlaceList () {
      const resp = await dspPlaceList()
      if (resp.code === 200) this.dspPlaceList = resp.data || []
    },
    async getAmountInfo () {
      const resp = await getAmountInfo()
      if (resp.code === 200) {
        this.totalAmount = resp.data.totalAmount
        this.balance = resp.data.balance
      }
    },
    // 查看充值记录
    lookRecords () {
      this.rechargeVisible = true
    },
    // 获取tablist
    async getTableList (data) {
      this.loadings = true
      const res = await adxCostpager({
        ...this.searchquery,
        ...this.query
      })
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        const aggregate = res.data.aggregate || {}
        aggregate.date = '汇总'
        aggregate.type = 'sum'
        this.tableData = [...[aggregate], ...tempData]
      }
      this.loadings = false
      this.total = res.data.total
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.searchquery.dateStart = dateStart
      this.searchquery.dateEnd = dateEnd
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 1).end
    },
    // 搜索
    handleAutoSearch () {
      this.loading = true
      this.query.page = 1
      //  请求列表
      this.getTableList({
        ...this.searchquery,
        ...this.query
      })
    },
    // 提示弹窗取消
    handleCancel () {
      this.selectedRowKeys = [] // 多选清空
    },
    // 分页
    handleCurrentPage (page) {
      this.query.page = page
      this.getTableList({
        ...this.searchquery,
        ...this.query
      })
    },
    handleChangePageSize (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.query
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';

.m-t-10 {
  margin-top: 10px;
}
.adxcost {
  margin: 0 10px;
  .adxcost_select {
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 20px;

    .search_item {
      margin-right: 10px;
      width: 220px;
      display: inline-block;
    }

  }
  .adxcost_middle {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    border-radius: 5px;

    .middle_con {
      color: #3366FF;
      display: flex;
      width: 50%;
      height: 36px;
      line-height: 36px;

      .con_pay {
        display: flex;
        flex-direction: row;
        width: 50%;
        span {
          font-size: 18px;
        }
      }
    }
  }

  .adxcost_table {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    .table_opr{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .dimension_list {
        display: flex;
        height: 100%;
        .ant-btn.ant-btn-link {
          padding: 0;
        }
        .dimension_item {
          padding: 5px 15px;
          background: #eee;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          .dimension_close {
            margin-left: 5px;
            line-height: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination {
    padding: 20px 0;
  }

  .pointer {
    cursor: pointer;
  }
  .action-slot{
    .btn{
      color: @primary-color;
      cursor: pointer;
    }
    .line{
      color: #ccc;
    }
    span{
      margin-right: 10px;
    }
  }
}
</style>
