<template>
  <div class="customer-table-container">
    <div class="control">
      <div class="control_left">
        <a-popover
          trigger="click"
          overlayClassName="media_popover"
          placement="bottomLeft"
          @visibleChange="visibleChange"
        >
          <template slot="content">
            <a-checkbox-group class="media_check_group" :value="groupByList" @change="handleChangeChecked">
              <a-checkbox class="check_box" value="app_id"> 应用 </a-checkbox>
              <a-checkbox class="check_box" value="place_id"> 广告位 </a-checkbox>
              <a-checkbox class="check_box" value="position_id"> 广告样式 </a-checkbox>
              <a-checkbox
                v-if="role !== 'ADVERTISERS' && role !== 'AGENT_ADVERTISERS' && role !== 'AGENT'"
                class="check_box"
                value="group_id"
              >
                流量分组
              </a-checkbox>
              <a-checkbox class="check_box" value="advertiser_id"> 广告主 </a-checkbox>
              <a-checkbox class="check_box" value="plan_id"> 广告计划 </a-checkbox>
              <a-checkbox class="check_box" value="plan_group_id"> 广告组 </a-checkbox>
              <a-checkbox class="check_box" value="ad_id"> 广告 </a-checkbox>
              <!-- <a-checkbox class="check_box" value="plan_creative_id"> 创意 </a-checkbox> -->
            </a-checkbox-group>
          </template>
          <a-button type="link"><a-icon type="radar-chart" />维度</a-button>
        </a-popover>
        <div v-for="(item, index) in searchquery.groupByList" class="dimension_item" :key="index">
          {{ dimensionName[item] }}<a-icon @click="handleCloseItem(item, index)" class="close_icon" type="close" />
        </div>
      </div>
    </div>
    <div class="table">
      <a-table
        class="m_table_cust customer_table"
        size="middle"
        :pagination="false"
        :rowKey="(record, index) => index"
        :data-source="dataList"
        :loading="isLoading"
        :scroll="{ x: true }"
        bordered
      >
        <!-- 日期 -->
        <a-table-column align="left" dataIndex="date" :width="100" :fixed="true">
          <span slot="title" class="hd_tips">日期</span>
        </a-table-column>
        <!-- 广告计划名称 -->
        <a-table-column v-if="isShowItem('plan_id')" align="left" dataIndex="adPlanName" :width="150" :fixed="true">
          <span slot="title" class="hd_tips">广告计划</span>
        </a-table-column>
        <!-- 应用名称 -->
        <a-table-column v-if="isShowItem('app_id')" align="left" dataIndex="appName" :width="150" :fixed="true">
          <span slot="title" class="hd_tips">应用</span>
        </a-table-column>
        <!-- 广告位名称 -->
        <a-table-column v-if="isShowItem('place_id')" align="left" dataIndex="placeName" :width="200" :fixed="true">
          <span slot="title" class="hd_tips">广告位</span>
          <template slot-scope="placeName">
            <div :title="placeName" style="width: 220px" class="overflow-text">{{ placeName }}</div>
          </template>
        </a-table-column>
        <!-- 广告样式名称 -->
        <a-table-column
          v-if="isShowItem('position_id')"
          align="center"
          dataIndex="positionName"
          :width="90"
          :fixed="true"
        >
          <span slot="title" class="hd_tips">广告样式</span>
        </a-table-column>
        <!-- 流量分组名称 -->
        <a-table-column v-if="isShowItem('group_id')" align="left" dataIndex="groupName" :width="100" :fixed="true">
          <span slot="title" class="hd_tips">流量分组</span>
          <template slot-scope="groupName">
            <div :title="groupName" style="width: 90px" class="overflow-text">{{ groupName }}</div>
          </template>
        </a-table-column>
        <!-- 广告主名称 -->
        <a-table-column
          v-if="isShowItem('advertiser_id')"
          align="center"
          dataIndex="advertiserName"
          :width="100"
          :fixed="true"
        >
          <span slot="title" class="hd_tips">广告主</span>
        </a-table-column>
        <!-- 广告组 -->
        <a-table-column
          v-if="isShowItem('plan_group_id')"
          align="center"
          dataIndex="adPlanGroupName"
          :width="100"
          :fixed="true"
        >
          <span slot="title" class="hd_tips">广告组</span>
          <template slot-scope="adPlanGroupName">
            <div :title="adPlanGroupName" class="overflow-text">{{ adPlanGroupName }}</div>
          </template>
        </a-table-column>
        <!-- 广告 -->
        <a-table-column v-if="isShowItem('ad_id')" align="center" dataIndex="adName" :width="100" :fixed="true">
          <span slot="title" class="hd_tips">广告</span>
          <template slot-scope="adName">
            <div :title="adName" class="overflow-text">{{ adName }}</div>
          </template>
        </a-table-column>
        <!-- 收益 -->
        <a-table-column align="left" dataIndex="income" width="100px">
          <span slot="title" class="hd_tips">{{
            role === 'ADVERTISERS' || role === 'AGENT_ADVERTISERS' || role === 'AGENT' ? '消耗' : '收益'
          }}</span>
        </a-table-column>
        <!-- 选择了广告筛选项以及广告维度，请求和填充率两列不显示 -->
        <template v-if="!(isShowItem('ad_id') || isShow)">
          <!-- 请求量 -->
          <a-table-column align="left" dataIndex="advRequest">
            <span slot="title" class="hd_tips">请求量</span>
          </a-table-column>
          <!-- 填充率 -->
          <a-table-column align="left" dataIndex="advRequestFilledRate">
            <span slot="title" class="hd_tips">填充率</span>
            <template slot-scope="advRequestFilledRate">{{ advRequestFilledRate }}%</template>
          </a-table-column>
        </template>
        <!-- 展示 -->
        <a-table-column align="left" dataIndex="impress">
          <span slot="title" class="hd_tips">展示</span>
        </a-table-column>
        <!-- 展示率 -->
        <a-table-column align="left" dataIndex="impressRate">
          <span slot="title" class="hd_tips">展示率</span>
          <template slot-scope="impressRate">{{ impressRate }}%</template>
        </a-table-column>
        <!-- 点击 -->
        <a-table-column align="left" dataIndex="click">
          <span slot="title" class="hd_tips">点击</span>
        </a-table-column>
        <!-- 点击率 -->
        <a-table-column align="left" dataIndex="clickRate">
          <span slot="title" class="hd_tips">点击率</span>
          <template slot-scope="clickRate">{{ clickRate }}%</template>
        </a-table-column>
        <!-- eCPM -->
        <a-table-column align="left" dataIndex="ecpm">
          <span slot="title" class="hd_tips">eCPM</span>
        </a-table-column>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 1"
        show-size-changer
        :current="searchquery.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
    </div>
    <m-empty v-if="dataList.length === 0" style="height: 70vh">
      <template #description>暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { getAdPlanReport } from '@/api/reportdatas'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
export default {
  data () {
    return {
      dimensionName: {
        app_id: ' 应用',
        place_id: '广告位',
        position_id: '广告样式',
        group_id: '流量分组',
        advertiser_id: '广告主',
        plan_id: '广告计划',
        plan_group_id: '广告组',
        ad_id: '广告'
        // plan_creative_id: '创意'
      },
      groupByList: [],
      searchquery: {
        groupByList: [],
        page: 1,
        limit: 10
      },
      dataList: [],
      total: 0,
      isLoading: true,
      downLoading: false,
      isShow: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  watch: {
    searchquery: {
      handler (val) {
        this.isShow = val?.adIdList?.length > 0
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 维度选择
    handleChangeChecked (e) {
      this.groupByList = e
    },
    // 关闭popover后进行维度搜索
    visibleChange (e) {
      if (e === false) {
        this.searchquery.groupByList = [...this.groupByList]
        this.getTableData()
      }
    },
    // 关闭单个维度
    handleCloseItem (item, index) {
      this.groupByList.splice(index, 1)
      this.searchquery.groupByList = [...this.groupByList]
      this.getTableData()
    },
    // 供父级调用
    dealquery (query) {
      this.searchquery.page = 1
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    // 获取table数据
    async getTableData () {
      this.isLoading = true
      const resp = await getAdPlanReport(this.searchquery)
      this.total = resp.data ? resp.data.total : 0
      const aggregate = resp.data && resp.data.aggregate ? resp.data.aggregate : []
      aggregate.length > 0 && (aggregate[0].date = '汇总')
      const items = resp.data && resp.data.item ? resp.data.item : []
      this.dataList = [...aggregate, ...items]
      this.dataList.length === 1 && (this.dataList = [])
      this.dataList.map((item) => {
        item.income = numFormat(item.income, 3, '')
        item.advRequest = numFormat(item.advRequest, 3, '')
        item.impress = numFormat(item.impress, 3, '')
        item.click = numFormat(item.click, 3, '')
        item.ecpm = numFormat(item.ecpm, 3, '')
      })
      this.isLoading = false
    },
    // 维度tableItem显示控制
    isShowItem (val) {
      return this.searchquery.groupByList.includes(val)
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    }
  }
}
</script>

<style lang="less">
.customer-table-container {
  .overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  background: #fff;
  padding: 0 20px;
  border-radius: 0 0 5px 5px;

  .control {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .control_left {
      display: flex;

      .dimension_item {
        user-select: none;
        line-height: 28px;
        height: 35px;
        margin: 0 10px;
        background: #eee;
        padding: 3px 10px;
        border-radius: 3px;

        .close_icon {
          color: #000;
          margin: 0 5px;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .table {
    .pagination {
      padding: 10px 0;
    }
  }
}

.media_popover {
  .ant-popover-inner-content {
    padding: 0;
  }

  .media_check_group {
    margin: 0;
    display: flex;
    width: 420px;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    .check_box.ant-checkbox-wrapper {
      box-sizing: border-box;
      width: 140px;
      height: 45px;
      line-height: 45px;
      margin: 0px;
      padding: 0 0 0 5px;

      &.ant-checkbox-wrapper-checked {
        color: #40a9ff;
      }
    }
  }

  .table {
    .customer_table {
      overflow: hidden;
    }
  }
}
</style>
