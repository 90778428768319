<template>
  <div class="search-template-container">
    <a-popover
      overlayClassName="templete_popover"
      ref="popover"
      trigger="click"
      placement="bottom"
      @visibleChange="visibleChange"
    >
      <div class="show_template_box">
        <div class="name">{{ currentTemplate.name || '请选择' }}</div>
        <div class="icon" :class="{ up_icon: !visb, down_icon: visb }"><a-icon type="down" /></div>
      </div>
      <template slot="content">
        <div class="popover_wrapper">
          <ul>
            <li
              @click="handleClickTemplateItem(item)"
              class="templete_item"
              v-for="item in searchTemplateList"
              :key="item.id"
              :class="{ active: currentTemplate.id === item.id }"
            >
              <span class="name">{{ item.name }}</span>
              <span
                class="control"
                @click.stop="handleDeleteTemplateItem(item)"
              ><a-icon
                style="color: red"
                type="delete"
              /></span>
            </li>
          </ul>
          <div class="add_templete" @click="popoverAddTemplete"><a-icon type="plus" />添加模板</div>
        </div>
      </template>
    </a-popover>
    <a-button
      :disabled="!currentTemplate.id"
      @click="handleEditTemplate"
      size="small"
      class="flex_item"
      type="primary"
    >保存</a-button
    >
    <a-button
      @click="handleAddNewTemplate"
      size="small"
      style="margin-left: 10px"
      class="flex_item"
      type=""
    >另存为</a-button
    >
    <a-modal
      :title="mode === 1 ? '新建模板' : '保存模板'"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      dialogClass="search_template_modal"
    >
      <a-form-model
        class="search_template_wrapper"
        ref="ruleForm"
        v-if="visible"
        :model="templatequery"
        v-bind="{
          labelCol: { span: 6 },
          wrapperCol: { span: 16 }
        }"
      >
        <a-form-model-item
          label="模板名称"
          prop="name"
          :rules="[{ required: true, message: '请输入模板名称', trigger: 'blur' }]"
        >
          <a-input v-model.trim="templatequery.name" style="width: 200px" placeholder="请输入模板名称" />
        </a-form-model-item>
        <a-form-model-item
          :rules="[{ required: true, message: '请输入数据时间范围', trigger: 'change' }]"
          label="数据时间范围"
          prop="requestParams.searchquery.days"
        >
          <a-select
            allowClear
            style="width: 200px"
            v-model="templatequery.requestParams.searchquery.days"
            placeholder="请选择数据时间范围"
          >
            <a-select-option :value="0">今日</a-select-option>
            <a-select-option :value="1">昨日</a-select-option>
            <a-select-option :value="7">最近七日</a-select-option>
            <a-select-option :value="14">最近十四日</a-select-option>
            <a-select-option :value="30">最近一个月</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model></a-modal
    >
  </div>
</template>

<script>
import { getReportTemplateList, insertReportTemplate, deleteReportTemplateSts } from '@/api/reportdatas'
export default {
  data () {
    return {
      // 模板列表
      searchTemplateList: [],
      // 当前选中模板信息
      currentTemplate: {
        name: '',
        id: ''
      },
      // 下拉框是否显示
      visb: false,
      mode: 1, // 1 新建 2 保存
      // modal是否显示
      visible: false,
      dateStr: '',
      // 表单
      templatequery: {
        name: undefined,
        requestParams: {}
      }
    }
  },
  watch: {
    searchTemplateList: {
      handler (val) {
        val.length === 0 &&
          (this.currentTemplate = {
            name: '',
            id: ''
          })
      }
    }
  },
  created () {
    this.getReportTemplateList()
  },
  methods: {
    // 下拉框出现隐藏回调
    visibleChange (visb) {
      this.visb = visb
    },
    // 获取模板列表
    async getReportTemplateList () {
      const resp = await getReportTemplateList()
      this.searchTemplateList = resp.data || []
    },
    // 点击模板后重置参数
    handleClickTemplateItem (item) {
      this.visb = false
      this.$refs.popover.$children[0].sVisible = false
      this.currentTemplate = item
      this.$emit('handleSetTemplate', JSON.parse(item.requestParams))
    },
    // modal确定按钮
    handleOk () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 1 新建 2 保存
          const query = {
            ...this.templatequery,
            requestParams: JSON.stringify(this.templatequery.requestParams)
          }
          const resp = await insertReportTemplate(query)
          if (resp.code === 200) {
            const msg = this.mode === 1 ? '添加成功！' : '保存成功！'
            this.$message.success(msg)
            this.getReportTemplateList()
            this.visible = false
          }
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    // 修改模板
    handleEditTemplate () {
      // 调用父级的方法获取当前参数
      this.templatequery.requestParams = this.$parent.handleGetTemplate()
      // 修改模式模板名称
      this.templatequery.name = this.currentTemplate.name
      // 修改模式模板ID
      this.templatequery.id = this.currentTemplate.id
      // 获取列表中相对应项的days
      this.templatequery.requestParams.searchquery.days = JSON.parse(
        this.searchTemplateList.find((item) => item.id === this.currentTemplate.id).requestParams
      ).searchquery.days
      this.visible = true
      this.mode = 2
    },
    // 添加新模板
    handleAddNewTemplate () {
      this.templatequery.requestParams = this.$parent.handleGetTemplate()
      // 初始化模板名称
      this.templatequery.name = ''
      // 删除id属性 （添加模式）
      delete this.templatequery.id
      this.visible = true
      this.mode = 1
    },
    // 下拉框中的添加模板
    popoverAddTemplete () {
      this.visb = false
      this.$refs.popover.$children[0].sVisible = false
      this.handleAddNewTemplate()
    },
    // 删除模版
    async handleDeleteTemplateItem (item) {
      deleteReportTemplateSts({ id: item.id, sts: 'D' }).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功！')
          this.getReportTemplateList()
          // 当前模板初始化
          this.currentTemplate = {
            name: '',
            id: ''
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.search-template-container {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.show_template_box {
  height: 30px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  width: 300px;
  line-height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  .icon {
    transition: all 0.2s;
    &.down_icon {
      transform: rotate(180deg);
    }
  }
}

.popover_wrapper {
  width: 300px;
  ul {
    margin: 0;
    padding: 0;
    .templete_item {
      padding: 3px 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #000;
      &.active {
        color: @primary-color;
        &:hover {
          color: @primary-color;
          opacity: 0.7;
        }
      }
      cursor: pointer;
      &:hover {
        color: #666;
        background: fade(@primary-color, 3%);
      }
    }
  }
  .add_templete {
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border-top: 1px solid #eee;
    &:hover {
      color: @primary-color;
    }
  }
}

.templete_popover .ant-popover-inner-content {
  padding: 0;
}
.search_template_modal .ant-modal-body {
  // background-color: #eee;
  .search_template_wrapper {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
  }
}
</style>
