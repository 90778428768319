<template>
  <div class="chart-item-container">
    <div class="control">
      <a-popover ref="popover" trigger="click" placement="rightTop">
        <template slot="content">
          <div class="indicator-wrapper">
            <div v-for="(obj, key) in indicatorObj" class="indicator_column" :key="key">
              <a-menu :selectedKeys="currentIndicator" type="inner" class="menu_checked" @select="handleChecked">
                <a-menu-item
                  :disabled="menuItemDisabled(item.hidden)"
                  class="menu_item"
                  v-for="item in obj.items"
                  :key="item.id"
                >
                  <div :title="item.name">
                    <span>{{ item.name }}</span>
                  </div>
                </a-menu-item>
              </a-menu>
            </div>
          </div>
        </template>
        <a-button
          type="link"
          size="small"
        >{{ currentIndicatorName }}<a-icon
          style="font-size: 12px"
          type="right"
        /></a-button>
      </a-popover>
      <a-button-group size="small">
        <a-button
          class="left_btn"
          :type="searchquery.groupBy ? null : 'primary'"
          @click="handleClickTotal"
        >合计</a-button
        >
        <a-dropdown-button
          class="right_btn"
          :trigger="['click']"
          size="small"
          :type="searchquery.groupBy ? 'primary' : null"
          @click="handleClickDimension(currentKey)"
        >
          <a-icon slot="icon" type="down" />
          {{ currentDimensionName }}
          <a-menu slot="overlay">
            <template v-for="item in dimensionArr">
              <a-menu-item @click="handleClickDimension(item.key)" :key="item.key" v-if="item.key !== currentKey">
                {{ item.name }}
              </a-menu-item>
            </template>
          </a-menu>
        </a-dropdown-button>
      </a-button-group>
    </div>
    <a-spin class="spin" :spinning="isLoading">
      <div class="chart" ref="chart"></div>
    </a-spin>
  </div>
</template>

<script>
import { dimensionArr, indicatorObj, options } from './optionsArr'
import { getSynthChart } from '@/api/reportdatas'
import { numFormat } from '@/utils/dealNumber'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      // 维度key
      currentKey: 1,
      // 维度属性
      currentKeyProp: 'appName',
      currentIndicator: ['dau'],
      searchquery: {
        groupBy: ''
      },
      // 维度数据
      dimensionArr,
      indicatorObj,
      options,
      // 指标数据
      indicatorDataList: [],
      myEcharts: null,
      dimensionDays: [],
      dimensionDataList: [],
      isTemplate: false,
      colorList: [
        'rgba(183, 105, 247, 1)',
        '#F87B68',
        'rgba(62, 219, 223, 1)',
        'rgba(247, 65, 108, 1)',
        'rgba(31, 125, 238, 1)',
        'rgba(252, 159, 74, 1)',
        '#39da61',
        'rgb(76, 180, 231)'
      ],
      isLoading: false
    }
  },
  computed: {
    currentDimensionName () {
      return this.dimensionArr.find((item) => item.key === this.currentKey)?.name
    },
    currentIndicatorName () {
      let name = ''
      for (const key in this.indicatorObj) {
        const item = this.indicatorObj[key].items.find((item) => item.id === this.currentIndicator[0])
        if (item) {
          name = item.name
        }
      }
      return name
    }
  },
  props: {
    indicator: {
      default: 'dau',
      type: String
    },
    isShow: {
      default: false,
      type: Boolean
    }
  },
  beforeMount () {
    this.searchquery = { ...this.searchquery, groupBy: this.searchquery.groupBy }
    this.currentIndicator = [this.indicator]
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    dealQuery (query) {
      // 监听参数获取数据
      this.searchquery = { ...query, groupBy: this.searchquery.groupBy }
      if (this.isTemplate) {
        this.isTemplate = false
        return
      }
      this.getChartData()
    },
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    // 当维度选择后，该数组内指标不允许选择
    menuItemDisabled (arr) {
      return arr.includes(this.searchquery.groupBy)
    },
    // 指标选择事件
    handleChecked ({ key }) {
      this.currentIndicator = [key]
      this.$refs.popover.$children[0].sVisible = false
      // 判断维度搜索和指标搜索
      if (!this.searchquery.groupBy) {
        this.dealIndicatorOptions()
      } else {
        this.dealDimensionOptions()
      }
    },
    // 指标点击事件
    handleClickDimension (id) {
      this.searchquery.groupBy = id
      this.currentKey = id
      this.currentKeyProp = this.dimensionArr.find((item) => item.key === this.currentKey).id
      this.currentIndicator = ['unitRevenue']
      this.getChartData()
    },
    // 合计按钮惦记事件
    handleClickTotal () {
      this.searchquery.groupBy = ''
      this.getChartData()
    },
    // 获取数据
    async getChartData () {
      this.isLoading = true
      const resp = await getSynthChart(this.searchquery)
      if (this.searchquery.groupBy === '') {
        // 指标数据
        this.indicatorDataList = resp.data ? resp.data.data : []
        this.dealIndicatorOptions()
      } else {
        // 维度数据
        this.dimensionDataList = resp.data ? resp.data.data : []
        this.dimensionDays = resp.data ? resp.data.lable : []
        this.dealDimensionOptions()
      }
      this.isLoading = false
    },
    // 指标数据处理
    dealIndicatorOptions () {
      this.options.xAxis.data = this.indicatorDataList.map((item) => item.date)
      this.options.xAxis.axisLabel.interval = Math.floor(this.indicatorDataList.length / 7)
      this.options.legend.data = [this.currentIndicatorName]
      this.options.legend.show = false
      const i = Math.floor(Math.random() * 8)
      this.options.series = [
        {
          name: this.currentIndicatorName,
          type: 'line',
          smooth: true,
          yAxisIndex: 0,
          data: this.indicatorDataList.map((item) => item[this.currentIndicator[0]]),
          symbol: 'circle', // 设定为实心点
          symbolSize: 5, // 设定实心点的大小
          itemStyle: {
              color: this.colorList[i],
              lineStyle: {
                color: this.colorList[i],
                width: 2
              }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.colorList[i] // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            }
          }
        }
      ]
      this.echartsInit()
    },
    // 维度数据处理
    dealDimensionOptions () {
      this.options.xAxis.data = this.dimensionDays
      // 维度top数组
      const arr = Object.keys(this.dimensionDataList)
      const legend = arr.map((item) => (!item ? ' ' : item))
      if (this.dimensionDataList.length > 0) {
        this.options.xAxis.axisLabel.interval = Math.floor(this.dimensionDataList[arr[0]].length / 7)
      }
      this.options.legend.data = legend
      this.options.legend.show = true
      this.options.series = arr.map((item, index) => {
        return {
          name: arr[index] !== '' ? arr[index] : ' ',
          type: 'line',
          smooth: true,
          yAxisIndex: 0,
          data: this.dimensionDataList[arr[index]].map((item) => {
            if (!item) {
              return 0
            } else {
              return item[this.currentIndicator[0]]
            }
          }),
          symbol: 'circle', // 设定为实心点
          symbolSize: 5, // 设定实心点的大小
          itemStyle: {
            normal: {
              color: this.colorList[index],
              lineStyle: {
                color: this.colorList[index],
                width: 2
              }
            }
          }
        }
      })
      this.echartsInit()
    },
    // 图表初始化
    echartsInit () {
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (
            this.currentIndicator[0] === 'unitRevenue' ||
            this.currentIndicator[0] === 'estimatedRevenueEcpm' ||
            this.currentIndicator[0] === 'income' ||
            this.currentIndicator[0] === 'arpu' ||
            this.currentIndicator[0] === 'arpDeu' ||
            this.currentIndicator[0] === 'unitEcpm'
          ) {
            temp.value = '￥' + numFormat(item.value, 3, '')
          } else if (
            this.currentIndicator[0] === 'permeability' ||
            this.currentIndicator[0] === 'newUserRate' ||
            this.currentIndicator[0] === 'requestFilledRate' ||
            this.currentIndicator[0] === 'impressRate' ||
            this.currentIndicator[0] === 'clickRate' ||
            this.currentIndicator[0] === 'unitCtr' ||
            this.currentIndicator[0] === 'impressionGap' ||
            this.currentIndicator[0] === 'clickGap' ||
            this.currentIndicator[0] === 'revenuePercentage' ||
            this.currentIndicator[0] === 'display' ||
            this.currentIndicator[0] === 'impressRequestRate'
          ) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          // str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
          str += `<div
                  style='
                    display:flex;
                    justify-content: space-between;
                  '>
                    <span
                      style='display:inline-block;min-width:120px'
                    >${
            item.marker + item.seriesName
          }:</span><span>${temp.value}</span></div>`
        })
        return params[0].axisValue + '<br>' + str
      }
      this.myEcharts && this.myEcharts.dispose()
      if (!this.$refs.chart) return
      if (this.myEcharts) {
        this.myEcharts.dispose()
        this.myEcharts = null
      }
      this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.chart-item-container {
  border: 1px solid #eee;
  padding: 10px;
  height: 350px;
  display: flex;
  flex-direction: column;
  .control {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    .ant-btn-group {
      .left_btn {
        border-radius: 5px 0 0 5px !important;
      }
      .ant-dropdown-trigger {
        border-radius: 0 5px 5px 0 !important;
      }
    }
  }
  .spin {
    height: 0;
    flex-grow: 1;
    position: relative;
    .ant-spin-container {
      height: 100%;
      .chart {
        height: 100%;
      }
    }
  }
}
.indicator-wrapper {
  position: relative;
  width: 400px;
  display: flex;
  .indicator_column {
    width: 25%;
    overflow: hidden;
    .menu_item {
      height: 25px;
      line-height: 25px;
      font-size: 12px;
    }
  }
}
</style>
