<template>
  <div class="media-search-container">
    <a-range-picker
      v-if="selectors.includes('1')"
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
      <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
    </a-range-picker>
    <m-select-more
      v-if="selectors.includes('2')"
      class="search_item"
      v-model="searchquery.appIdList"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
    />
    <m-select-more
      v-if="selectors.includes('3')"
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      v-if="selectors.includes('4')"
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      v-if="selectors.includes('5')"
      class="search_item"
      v-model="searchquery.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      v-if="selectors.includes('6')"
      class="search_item"
      v-model="searchquery.osIdList"
      :allData="[
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ]"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="操作系统"
      :showSelectedPart="false"
      width="240px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appIdList: [],
        placeIdList: [],
        positionIdList: [],
        platIdList: [],
        osIdList: []
      }
    }
  },
  props: {
    selectors: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title,
      role: (state) => state.user.roles[0]
    })
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.getAppList()
    this.getPlaceList()
    this.getPositionList()
    this.getPlatList()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间
      return current >= this.initDate(0, 1).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.media-search-container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search_item {
    padding: 10px 5px;
    width: 220px;
    display: inline-block;
  }
}
</style>
