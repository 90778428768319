<template>
  <div class="media-report-container">
    <MediaSearch @handleAutoSearch="handleAutoSearch" :selectors="selectors" />
    <MediaTable ref="table" :datas="datas" />
  </div>
</template>

<script>
import MediaSearch from './MediaSearch'
import MediaTable from './MediaTable'
import { getMediaReportRuleVo } from '@/api/reportdatas'
export default {
  components: { MediaSearch, MediaTable },
  data () {
    return {
      selectors: [],
      datas: []
    }
  },
  created () {
    this.getMediaReportRuleVo()
  },
  methods: {
    async getMediaReportRuleVo () {
      const resp = await getMediaReportRuleVo()
      if (resp.data) {
        const { selector = [], sort = [] } = resp.data
        this.selectors = selector
        this.datas = sort
      }
    },
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealquery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.media-report-container {
  margin: 0 10px 10px 10px;
}
</style>
