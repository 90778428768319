<template>
  <div class="synth-chart-container">
    <div class="header">
      <div class="title">数据图表</div>
      <div class="control">
        <div class="expend_btn" @click="isExpend = !isExpend">
          <span><a-icon class="icon" :type="isExpend ? 'up-circle' : 'down-circle'" />展开</span>
        </div>
        <div class="count">
          <!-- <img src="" alt=""> -->
          <img src="/images/report/normal.png" alt="" class="img-icon" @click="changeTwoRow"/>
          <img
            src="/images/report/more.png"
            alt=""
            class="img-icon"
            @click="() => {
              twoRow = true
            }"
          />
        </div>
      </div>
    </div>

    <div class="chart_groups" :class="{ isExpend: isExpend }">
      <ChartItem ref="chart_1" indicator="dau" v-on="$listeners" class="chart_item" />
      <ChartItem ref="chart_2" indicator="estimatedRevenueEcpm" v-on="$listeners" class="chart_item" />
      <ChartItem
        ref="chart_3"
        v-if="!twoRow"
        :isShow="!twoRow"
        :indicator="indicator_3"
        v-on="$listeners"
        class="chart_item"
      />
      <ChartItem
        ref="chart_4"
        v-if="!twoRow"
        :isShow="!twoRow"
        :indicator="indicator_4"
        v-on="$listeners"
        class="chart_item"
      />
    </div>
  </div>
</template>

<script>
import ChartItem from './ChartItem'
import arrowUpSvg from '@/assets/icons/arrowUp.svg?inline'
import arrowDownSvg from '@/assets/icons/arrowDown.svg?inline'
export default {
  components: { ChartItem, arrowUpSvg, arrowDownSvg },
  data () {
    return {
      twoRow: true,
      query: {},
      indicator_3: 'unitRevenue',
      indicator_4: 'arpu',
      isExpend: true
    }
  },
  methods: {
    changeTwoRow () {
      this.twoRow = false
      this.$nextTick(() => {
        this.$refs['chart_3'].dealQuery(this.query)
        this.$refs['chart_4'].dealQuery(this.query)
      })
    },
    dealQuery (query) {
      this.query = query
      this.$nextTick(() => {
        this.$refs['chart_1'].dealQuery(query)
        this.$refs['chart_2'].dealQuery(query)
        if (!this.twoRow) {
          this.$refs['chart_3'].dealQuery(query)
          this.$refs['chart_4'].dealQuery(query)
        }
      })
    },
    // 供父组件调用，获取图表模块参数
    getChartQuery () {
      // 指标数组
      let indicatorArr = [this.$refs['chart_1'].currentIndicator[0], this.$refs['chart_2'].currentIndicator[0]]
      // 维度数组
      let groupByArr = [this.$refs['chart_1'].searchquery.groupBy, this.$refs['chart_2'].searchquery.groupBy]
      // 是否开启四个chart
      if (!this.twoRow) {
        indicatorArr = [
          ...indicatorArr,
          this.$refs['chart_3'].currentIndicator[0],
          this.$refs['chart_4'].currentIndicator[0]
        ]
        groupByArr = [
          ...groupByArr,
          this.$refs['chart_3'].searchquery.groupBy,
          this.$refs['chart_4'].searchquery.groupBy
        ]
      }
      return { indicatorArr, groupByArr }
    },
    // 供父级调用设置各个chart参数
    async setChartQuery (chartquery, searchquery) {
      this.query = searchquery
      const { groupByArr, indicatorArr } = chartquery
      this.twoRow = groupByArr.length === 2
      // 各个chart的参数设置
      await this.setChartItem(groupByArr, indicatorArr, searchquery, 1)
      await this.setChartItem(groupByArr, indicatorArr, searchquery, 2)
      this.$nextTick(() => {
        if (!this.twoRow) {
          this.setChartItem(groupByArr, indicatorArr, searchquery, 3)
          // chart以模板方式填充参数
          this.$refs['chart_3'].isTemplate = true
          this.setChartItem(groupByArr, indicatorArr, searchquery, 4)
          // chart以模板方式填充参数
          this.$refs['chart_4'].isTemplate = true
        }
      })
    },
    // 设置每个chart的参数
    setChartItem (groupByArr, indicatorArr, searchquery, index) {
      // 当前指标
      this.$refs['chart_' + index].currentIndicator = [indicatorArr[index - 1]]
      // 保存指标
      index === 3 && (this.indicator_3 = indicatorArr[index - 1])
      index === 4 && (this.indicator_4 = indicatorArr[index - 1])
      // 当前维度
      this.$refs['chart_' + index].searchquery.groupBy = groupByArr[index - 1]
      // 当前的维度回显
      groupByArr[index - 1] && (this.$refs['chart_' + index].currentKey = groupByArr[index - 1])
      this.$refs['chart_' + index].dealQuery(searchquery)
    }
  }
}
</script>

<style lang="less">
.synth-chart-container {
  border-radius: 5px;
  background: #fff;
  margin-top: 10px;

  .header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    border-bottom: #eee;
    .control{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .expend_btn {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: @primary-color;
      cursor: pointer;
      .icon{
        width: 10px;
        height: 10px;
        margin-right: 8px;
      }
    }
    .count {
      display: flex;
      margin-left: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      height: 30px;
      width: 70px;
      padding: 0 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img-icon {
        display: inline-block;
        color: @primary-color;
        cursor: pointer;
        width: 16px;
        height: 16px;
        &:hover{
          background: #f2f2f2;
        }
      }
    }
  }
  .chart_groups {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: all 1s;
    &.isExpend {
      height: 0;
    }
    .chart_item {
      width: 50%;
    }
  }
}
</style>
