/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-19 14:23:50
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-29 17:20:31
 * @FilePath: /mediatom-web/src/mixins/fetchPool.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {
  CancelToken
} from 'axios'
import store from '@/store'
export default {
  data () {
    return {
      cancelSource: null
    }
  },
  methods: {
    /**
     * 添加请求取消指令
     * @param {string} key
     * */
    getCancelToken (key) {
      this.cancelSource = CancelToken.source()
      store.dispatch('setFeatPool', {
        cancelSource: this.cancelSource,
        key
      })
    },
    /**
     * 移除指令池中的指令
     * @param {string} key
     * */
    removeCancelSource (key) {
      store.dispatch('removePool', key)
    }
  }
}
