<template>
  <div class="customer-report-container">
    <CustomerSearch @handleAutoSearch="handleAutoSearch" />
    <CustomerTable ref="table" />
  </div>
</template>

<script>
import CustomerSearch from './CustomerSearch'
import CustomerTable from './CustomerTable'
export default {
  components: { CustomerSearch, CustomerTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.dealquery(query)
      })
    }
  }
}
</script>

<style lang="less">
.customer-report-container {
  padding: 0 10px 10px 10px;
}
</style>
