<template>
  <div class="synth-Report-container">
    <SearchTemplate @handleSetTemplate="handleSetTemplate" />
    <SynthSearth ref="search" @handleAutoSearch="handleAutoSearch" />
    <SynthChart ref="chart" />
    <SynthTable ref="table" />
  </div>
</template>

<script>
import SearchTemplate from './SearchTemplate'
import SynthSearth from './SynthSearch'
import SynthChart from './SynthChart'
import SynthTable from './SynthTable'
import mixDate from '@/mixins/initDate'
import custTagObj from './SynthTable/localColumns'
export default {
  name: 'SynthReport',
  mixins: [mixDate],
  components: { SynthSearth, SynthChart, SynthTable, SearchTemplate },
  data () {
    return {
      query: {},
      // table维度列表
      allDimesion: [
        {
          key: 'app_id',
          isWrap: false,
          name: '应用',
          checked: false,
          prop: 'appName',
          width: 120
        },
        {
          key: 'place_id',
          isWrap: false,
          name: '广告位',
          checked: false,
          prop: 'placeName',
          width: 150
        },
        {
          key: 'group_id',
          isWrap: false,
          name: '流量分组',
          checked: false,
          prop: 'groupName',
          width: 120
        },
        {
          key: 'test_id',
          isWrap: false,
          name: 'A/B测试',
          checked: false,
          prop: 'testGroupName',
          width: 100
        },
        {
          key: 'channel',
          isWrap: false,
          name: '渠道',
          checked: false,
          prop: 'channel',
          width: 100
        },
        {
          key: 'ad_source_id',
          isWrap: false,
          name: '广告源',
          checked: false,
          prop: 'sourceName',
          width: 120
        },
        {
          key: 'position_id',
          isWrap: false,
          name: '广告样式',
          checked: false,
          prop: 'positionName',
          width: 80
        },
        {
          key: 'sdk_version',
          isWrap: false,
          name: 'SDK版本',
          checked: false,
          prop: 'sdkVersion',
          width: 80
        },
        {
          key: 'plat_id',
          isWrap: false,
          name: '广告平台',
          checked: false,
          prop: 'platName',
          width: 100
        },
        {
          key: 'app_version',
          isWrap: false,
          name: '应用版本',
          checked: false,
          prop: 'appVersion',
          width: 100
        }
      ],
      // 综合报表的自定指标列表
      custTagObj
    }
  },
  mounted () {},
  methods: {
    // 搜索项参数处理
    handleAutoSearch (val) {
      let query = JSON.parse(JSON.stringify(val))
      if (val.adminUserIds.length === 1 && !val.adminUserIds[0]) {
        query = { ...val, adminUserIds: [] }
      }
      this.query = query
      this.$nextTick(() => {
        this.$refs.table.dealQuery(this.query)
        this.$refs.chart.dealQuery(this.query)
      })
    },
    /**
     * 供template组件使用，返回各个组件参数集合
     */
    handleGetTemplate () {
      // search
      const searchquery = { ...this.$refs.search.searchquery, days: 1 }
      // 判断账号传参
      searchquery.adminUserIds.length === 1 && !searchquery.adminUserIds[0] && (searchquery.adminUserIds = [])
      // chart
      const chartquery = this.$refs.chart.getChartQuery()
      // table
      const groupByList = this.$refs.table.searchquery.groupByList
      const sureArr = this.$refs.table.sureArr.map((item) => item.prop)
      const tablequery = { groupByList, sureArr }
      return {
        searchquery,
        chartquery,
        tablequery
      }
    },
    handleSetTemplate (requestParams) {
      let { searchquery } = requestParams
      const { chartquery, tablequery } = requestParams
      // search
      let timeObj
      // 处理近期天数
      if (searchquery.days === 1) {
        // 特殊处理前一天
        timeObj = this.initDate(1, 1)
      } else {
        // 其他天数
        timeObj = this.initDate(searchquery.days, 1)
      }
      const { endDate, startDate, end, start } = timeObj
      searchquery = {
        ...searchquery,
        dateEnd: endDate,
        dateStart: startDate
      }
      this.$refs.search.searchquery = searchquery
      this.$refs.search.rangePickerDate = [start, end]
      // 模版方式填充参数
      this.$refs.search.isTemplate = true
      // 账号特殊处理
      this.$refs.search.changeUserId(searchquery.adminUserIds[0] ? searchquery.adminUserIds[0] : undefined)
      // chart
      this.$refs.chart.setChartQuery(chartquery, searchquery)
      // table
      // 传入维度获取数据
      this.$refs.table.searchquery = {
        ...this.$refs.table.searchquery,
        ...searchquery,
        page: 1
      }
      let sureArr = []
      // 遍历全部指标
      // 保存已选指标
      // 初始化总列表
      const allTags = []
      this.custTagObj.targetArr.forEach((item) => {
        allTags.push(...item.list)
      })
      // sureArr = allTags.filter((item) => tablequery.sureArr.includes(item.prop))
      sureArr = tablequery.sureArr.map((item) => allTags.find((i) => i.prop === item))
      // 调用指标弹窗确定回调
      this.$refs.table.changeCustTags(this.custTagObj.targetArr, sureArr)
      const selectDimesions = this.allDimesion.filter((item) => tablequery.groupByList.includes(item.key))
      this.$refs.table.changeCustDimension(selectDimesions)
    }
  }
}
</script>

<style lang="less">
.synth-Report-container {
  margin: 0 10px 10px 10px;
}
</style>
