<template>
  <div class="funnel-box-container">
    <Funnel :has-impress="hasImpress" :funnel-data="funnelData" class="funnel" />
    <div class="funnel_table">
      <a-table
        class="m_table_cust"
        :loading="isLoading"
        size="middle"
        :rowKey="(record, index) => index"
        :data-source="dataList || []"
        :pagination="false"
        bordered
      >
        <!-- 日期 -->
        <a-table-column align="left" dataIndex="date" :width="100">
          <span slot="title" class="hd_tips">日期</span>
        </a-table-column>
        <!-- 应用启动 -->
        <a-table-column align="left" dataIndex="startApp" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`应用启动并成功初始化${title} SDK`"
            title="应用启动"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 获取配置 -->
        <a-table-column align="left" dataIndex="configNum" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`应用向${title}服务器请求广告位配置策略`"
            title="获取配置"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 流量请求 -->
        <a-table-column align="left" dataIndex="request" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`应用向${title}发起广告请求的次数`"
            title="流量请求"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 流量填充率 -->
        <a-table-column align="left" dataIndex="requestFilledRate" :width="120">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}向广告平台发送请求后返回成功的占比`"
            title="流量填充率"
            :width="150"
          ></m-tips>
          <template slot-scope="requestFilledRate">{{ requestFilledRate }}%</template>
        </a-table-column>
        <!-- 触发展示 -->
        <a-table-column align="left" dataIndex="triggerImpress" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`应用调用${title} 的Show接口(原生、横幅广告为触发三方广告的展示逻辑)`"
            title="触发展示"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 广告触发率 -->
        <a-table-column align="left" dataIndex="advTriggerRate" :width="120">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`触发展示 / 流量填充 * 100%`"
            title="广告触发率"
            :width="150"
          ></m-tips>
          <template slot-scope="advTriggerRate">{{ advTriggerRate }}%</template>
        </a-table-column>
        <!-- 展示成功率 -->
        <a-table-column align="left" dataIndex="impressSuccessRate" :width="120">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`展示 / 触发展示 * 100%`"
            title="展示成功率"
            :width="150"
          ></m-tips>
          <template slot-scope="impressSuccessRate">{{ impressSuccessRate }}%</template>
        </a-table-column>
        <!-- 展示 -->
        <a-table-column align="left" dataIndex="impress" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`"
            title="展示"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 展示API -->
        <a-table-column v-if="hasImpress" align="left" dataIndex="unitImpression" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}通过Reporting API向广告平台拉取到的展示`"
            title="展示API"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 展示GAP -->
        <a-table-column v-if="hasImpress" align="left" dataIndex="impressionGap" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}统计展示与广告平台统计展示的差异，公式为 展示Gap=(展示-展示API)/展示API`"
            title="展示GAP"
            :width="150"
          ></m-tips>
          <template slot-scope="impressionGap">{{ impressionGap }}%</template>
        </a-table-column>
        <!-- 点击 -->
        <a-table-column align="left" dataIndex="click" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`"
            title="点击"
            :width="150"
          ></m-tips>
        </a-table-column>
        <!-- 点击率 -->
        <a-table-column align="left" dataIndex="clickRate" :width="100">
          <m-tips
            slot="title"
            class="hd_tips"
            :content="`${title}统计的点击率，点击率=点击数/展示数`"
            title="点击率"
            :width="150"
          ></m-tips>
          <template slot-scope="clickRate">{{ clickRate }}%</template>
        </a-table-column>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        @change="handleChangePage"
        :show-total="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        :pageSize="searchquery.limit"
      />
      <m-empty v-else style="height: 500px">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>

<script>
import Funnel from './Funnel'
import { getFunnelReport } from '@/api/reportdatas'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: { Funnel },
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appIdList: [],
        placeIdList: [],
        groupIdList: [],
        channelIdList: [],
        positionIdList: [],
        sdkVersion: '',
        appVersion: ''
      },
      funnelData: {},
      dataList: [],
      total: 0,
      isLoading: false
    }
  },
  computed: {
    hasImpress () {
      if (
        (this.searchquery.sdkVersion && this.searchquery.sdkVersion.length > 0) ||
        (this.searchquery.appVersion && this.searchquery.appVersion.length > 0) ||
        (this.searchquery.groupIdList && this.searchquery.groupIdList.length > 0) ||
        (this.searchquery.channelIdList && this.searchquery.channelIdList.length > 0)
      ) {
        return true
      } else {
        return false
      }
    },
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    query: {
      handler (val) {
        const searchquery = JSON.parse(JSON.stringify(val))
        this.searchquery = { ...searchquery, limit: 10, page: 1, groupByList: ['date'] }
        this.getTableList()
      },
      deep: true
    }
  },
  methods: {
    async getTableList () {
      this.isLoading = true
      const resp = await getFunnelReport(this.searchquery)
      this.funnelData = resp.data ? resp.data.aggregate : []
      this.funnelData.date = '汇总'
      const items = resp.data ? resp.data.items : []
      this.dataList = [this.funnelData, ...items]
      this.dataList.length === 1 && (this.dataList = [])
      this.total = resp.data ? resp.data.total : 0
      this.dataList.map((item) => {
        item.startApp = numFormat(item.startApp, 3, '')
        item.configNum = numFormat(item.configNum, 3, '')
        item.request = numFormat(item.request, 3, '')
        item.triggerImpress = numFormat(item.triggerImpress, 3, '')
        item.impress = numFormat(item.impress, 3, '')
        item.click = numFormat(item.click, 3, '')
      })
      this.isLoading = false
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableList()
    }
  }
}
</script>

<style lang="less">
.funnel-box-container {
  margin-top: 10px;
  .funnel_table {
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    background: #fff;
    .hd_tips {
      font-weight: 600;
    }
    .pagination {
      padding-top: 20px;
    }
  }
}
</style>
