<template>
  <div class="warning-edit-container">
    <a-page-header style="background: #fff; border-radius: 5px" @back="goback">
      <span slot="title" style="font-size: 16px; font-weight: 600">返回预警规则</span></a-page-header
      >
    <div class="form_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <div class="form-row">
          <div class="form-row-item">
            <a-form-model-item
              label="预警名称"
              prop="name"
              :rules="[{ required: true, message: '请输入预警名称', trigger: 'blur' }]"
            >
              <a-input style="width: 260px" placeholder="请输入预警名称" v-model.trim="formquery.name"></a-input>
            </a-form-model-item>
          </div>
          <div class="form-row-item">
            <a-form-model-item
              label="预警对象"
              prop="target"
              :rules="[{ required: true, message: '请选择预警对象', trigger: 'change' }]"
            >
              <a-select
                placeholder="请选择预警对象"
                style="width: 260px"
                v-model="formquery.target"
                @change="changeFrequencyAndTarget"
              >
                <a-select-option value="1">应用</a-select-option>
                <a-select-option value="2">广告位</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="form-row-item">
            <a-form-model-item
              label="应用"
              prop="appIdList"
              :rules="[{ required: true, message: '请选择应用', trigger: 'change' }]"
            >
              <m-select-more
                v-if="showAppSelect"
                style="width: 260px"
                class="search_item"
                v-model="formquery.appIdList"
                :allData="appList"
                :searchById="true"
                :hasIcon="true"
                :showId="true"
                width="450px"
                label="应用"
                :showLabel="false"
                @change="changeAppIds"
              /></a-form-model-item>
          </div>
          <div class="form-row-item">
            <a-form-model-item
              v-if="formquery.target === '2'"
              label="广告位"
              prop="placeIdList"
              :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
            >
              <m-select-more
                v-if="showAppSelect"
                style="width: 260px"
                class="search_item"
                v-model="formquery.placeIdList"
                :allData="placeList"
                :searchById="true"
                :hasIcon="true"
                :showId="true"
                width="450px"
                :showLabel="false"
                label="广告位"
                @change="changePlaceIds"
              /></a-form-model-item>
          </div>
        </div>

        <a-form-model-item
          label="预警频率"
          prop="frequency"
          :rules="[{ required: true, message: '请选择预警频率', trigger: 'change' }]"
        >
          <a-radio-group v-model="formquery.frequency" @change="changeFrequencyAndTarget">
            <a-radio :value="1"> 天级 </a-radio><a-radio :value="2"> 小时级 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="预警触发周期"
          prop="triggerdaysList"
          :rules="[{ required: true, message: '请选择预警触发周期', trigger: 'change' }]"
        >
          <a-checkbox-group v-model="formquery.triggerdaysList">
            <a-checkbox v-for="item in weekList" :key="item.value" :value="item.value" >{{ item.label }}</a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item
          label="预警条件"
          prop="triggerCondition"
          :rules="[{ required: true, message: '请选择预警条件', trigger: 'change' }]"
        >
          <div class="num">{{ `已添加(${formquery.earlyWarningRuleList?.length}/5)` }}</div>
          <div class="rule_list">
            <div class="rule_list_header">
              <a-radio-group v-model="formquery.triggerCondition" @change="changeFrequencyAndTarget">
                <a-radio :value="0"> 满足以下所有条件时触发 </a-radio
                ><a-radio :value="1"> 满足以下任一条件时触发 </a-radio>
              </a-radio-group>
            </div>
            <div class="rule_list_main">
              <div class="rule_line" v-if="formquery.earlyWarningRuleList?.length > 1">
                <span>{{ formquery.triggerCondition === 0 ? '且' : '或' }}</span>
              </div>
              <div class="rule_box">
                <div class="rule_item" v-for="(item, index) in formquery.earlyWarningRuleList" :key="index">
                  <div class="item">
                    <a-tag>{{ item.frequency == 1 ? '昨天' : '今天' }}</a-tag>
                  </div>
                  <div class="item">
                    <a-form-model-item
                      :prop="`earlyWarningRuleList[${index}].ruleId`"
                      :rules="[{ required: true, message: '请选择数据指标', trigger: 'change' }]"
                    >
                      <a-select
                        style="width: 140px"
                        placeholder="请选择数据指标"
                        size="small"
                        v-model="formquery.earlyWarningRuleList[index].ruleId"
                        allowClear
                      >
                        <a-select-option
                          v-if="formquery.frequency === 1 && formquery.target === '1'"
                          :value="1"
                        >DAU</a-select-option
                        >
                        <a-select-option
                          v-if="formquery.frequency === 1 && formquery.target === '1'"
                          :value="2"
                        >ARPDAU</a-select-option
                        >
                        <a-select-option :value="3">流量请求</a-select-option>
                        <a-select-option :value="4">流量填充率</a-select-option>
                        <a-select-option :value="5">展示</a-select-option>
                        <a-select-option :value="6">预估收益</a-select-option>
                        <a-select-option :value="7">展示率</a-select-option>
                        <a-select-option v-if="formquery.target === '2'" :value="8">预估eCPM</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <div class="item">
                    <a-form-model-item :prop="`earlyWarningRuleList[${index}].ruleType`">
                      <a-select
                        style="width: 60px"
                        size="small"
                        v-model="formquery.earlyWarningRuleList[index].ruleType"
                      >
                        <a-select-option :value="0">≤</a-select-option>
                        <a-select-option :value="1">≥</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <div class="item">
                    <a-form-model-item
                      :prop="`earlyWarningRuleList[${index}].dateType`"
                      :rules="[{ required: true, message: '请选择时间类型', trigger: 'change' }]"
                    >
                      <a-select
                        style="width: 100px"
                        size="small"
                        v-model="formquery.earlyWarningRuleList[index].dateType"
                        placeholder="请选择时间类型"
                      >
                        <a-select-option v-if="formquery.frequency === 1" :value="0">前一天</a-select-option>
                        <a-select-option v-if="formquery.frequency === 2" :value="1">昨天</a-select-option>
                        <a-select-option :value="2">上周同一天</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                  <div class="item">
                    <a-form-model-item
                      :prop="`earlyWarningRuleList[${index}].value`"
                      :rules="[{ required: true, message: '请输入数据指标', trigger: 'blur' }]"
                    >
                      <a-input
                        size="small"
                        style="width: 150px"
                        addon-after="%"
                        v-model.number.trim="formquery.earlyWarningRuleList[index].value"
                        placeholder="请输入数据指标"
                      />
                    </a-form-model-item>
                  </div>
                  <div class="item icon">
                    <a-icon
                      v-if="formquery.earlyWarningRuleList?.length > 1"
                      style="font-size: 18px"
                      type="delete"
                      @click="handleDeleteRuleItem(index)"
                    />
                  </div>
                </div>
              </div>
              <a-button class="add_btn" icon="plus" @click="handleAddRuleItem">添加</a-button>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="预警通知">
          <a-alert style="width: 400px" message="预警频率：预估'每天八点之前'发出预警" banner />
        </a-form-model-item>
        <a-form-model-item label="预警通知方式" prop="noticeType">
          <a-radio-group v-model="formquery.noticeType">
            <a-radio :value="1"> 站内信 </a-radio>
            <a-radio :value="2"> 邮箱 </a-radio>
            <a-radio :value="3"> 站内信+邮箱 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="formquery.noticeType === 1 || formquery.noticeType === 3"
          label="站内信"
          prop="userIdList"
          :rules="[{ required: true, message: '请选择站内信', trigger: 'change' }]"
        >
          <a-select
            :maxTagCount="1"
            mode="multiple"
            style="width: 200px"
            v-model="formquery.userIdList"
            placeholder="请选择站内信"
          >
            <a-select-option v-for="(item, index) in subList" :key="index" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="formquery.noticeType === 2 || formquery.noticeType === 3"
          label="邮箱"
          prop="emailstr"
          :rules="[{ required: true, message: '请选择站内信', trigger: 'change' }]"
        >
          <a-textarea v-model="formquery.emailstr" placeholder="多个邮箱请以英文逗号隔开" style="width: 300px" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="edit_rule_footer">
      <a-button @click="handleSumbit" type="primary">{{ isAdd ? '添加' : '修改' }}</a-button>
    </div>
  </div>
</template>

<script>
import mixGetList from '@/mixins/getList'
import { getSubList, updateWarning, addWarning } from '@/api/reportdatas'
export default {
  name: 'WarningupEdit',
  mixins: [mixGetList],
  data () {
    return {
      showAppSelect: false,
      formquery: {
        name: undefined,
        target: undefined,
        appIdList: [],
        placeIdList: [],
        frequency: 1,
        triggerCondition: 1,
        earlyWarningRuleList: [
          {
            ruleId: undefined,
            ruleType: 1,
            dateType: 0,
            value: undefined
          }
        ],
        noticeType: 1,
        userIdList: [],
        emailstr: undefined,
        emailList: [],
        triggerdaysList: []
      },
      rules: {},
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 }
      },
      subList: [],
      isAdd: false,
      weekList: [
        { label: '周一', value: '1' },
        { label: '周二', value: '2' },
        { label: '周三', value: '3' },
        { label: '周四', value: '4' },
        { label: '周五', value: '5' },
        { label: '周六', value: '6' },
        { label: '周日', value: '7' }
      ]
    }
  },
  async mounted () {
    await this.getAppList()
    this.getSubList()
    if (this.formquery.placeIdList?.length) {
      await this.getPlaceListByAppIds(this.formquery.appIdList)
    }
    this.showAppSelect = true
  },
  methods: {
    // 供父级调用
    dealquery (query, isAdd) {
      this.isAdd = isAdd
      if (!isAdd) {
        this.formquery = JSON.parse(JSON.stringify(query))
        this.$set(this.formquery, 'emailstr', (this.formquery.emailList || []).join())
        this.$set(
          this.formquery,
          'userIdList',
          (this.formquery.userIdList || []).map((item) => +item)
        )
      }
    },
    // 站内信列表
    getSubList () {
      getSubList().then((res) => {
        this.subList = res.data || []
      })
    },
    // 返回
    goback () {
      this.$emit('toRule')
    },
    // 获取app列表
    changeAppIds (ids) {
      this.getPlaceListByAppIds(ids)
    },
    //
    changePlaceIds (ids) {},
    // 修改预警对象或时间级别后得处理
    changeFrequencyAndTarget () {
      this.formquery.earlyWarningRuleList.forEach((item) => {
        item.dateType = this.formquery.frequency === 1 ? 0 : this.formquery.frequency === 2 ? 1 : undefined
        item.ruleId = undefined
      })
    },
    // 添加预警规则item
    handleAddRuleItem () {
      if (this.formquery.earlyWarningRuleList?.length >= 5) {
        this.$message.warning('已达上限！')
        return
      }
      this.formquery.earlyWarningRuleList.push({
        ruleId: undefined,
        ruleType: 1,
        dateType: this.formquery.frequency === 1 ? 0 : this.formquery.frequency === 2 ? 1 : undefined,
        value: undefined
      })
    },
    // 删除预警规则item
    handleDeleteRuleItem (index) {
      this.formquery.earlyWarningRuleList.splice(index, 1)
    },
    // 确定按钮
    handleSumbit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.formquery.emailList = (this.formquery.emailstr || '').split(',')
          if (this.isAdd) {
            const resp = await addWarning(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.goback()
            }
          } else {
            const resp = await updateWarning(this.formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.goback()
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.warning-edit-container {
  height: 84vh;
  display: flex;
  flex-direction: column;
  .form_wrapper {
    flex-grow: 1;
    height: 0;
    border-radius: 5px;
    background: #fff;
    margin-top: 10px;
    padding: 20px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
    .form-row{
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      .form-row-item{
        width: 48%;
      box-sizing: border-box;
      padding-left: 100px;
      }
    }
    .rule_list {
      padding: 0;
      margin: 0;
      width: 70%;
      border: 1px solid #ddd;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .rule_list_header {
        padding-left: 20px;
        border-bottom: 1px solid #ddd;
        background-color: #eee;
      }
      .rule_list_main {
        flex: 1;
        display: flex;
        position: relative;
        position: relative;
        padding-bottom: 50px;
        .rule_line {
          border-right: 2px solid #ddd;
          margin-left: 20px;
          margin: 10px 10px 10px 0px;
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            transform: translate(13px, 0px);
            display: inline-block;
            width: 25px;
            height: 25px;
            line-height: 20px;
            text-align: center;
            background: #fff;
          }
        }
        .rule_box {
          flex: 1;
          display: flex;
          flex-direction: column;
          .rule_item {
            position: relative;
            height: 50px;
            line-height: 40px;
            display: flex;
            justify-content: space-around;
            padding-right: 20px;
            .item.icon {
              position: absolute;
              right: 5px;
              top: 2px;
              font-size: 18px;
              color: red;
              cursor: pointer;
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
        .add_btn {
          width: 99%;
          border: 1px solid @primary-color;
          color: @primary-color;
          margin: 0 auto;
          height: 30px;
          position: absolute;
          left: 0;
          right: 0;
          padding: 0;
          bottom: 2px;
        }
      }
    }
  }
  .edit_rule_footer {
    display: flex;
    background: #fff;
    justify-content: center;
    border-radius: 5px;
    padding: 20px 0;
    height: 80px;
    button {
      width: 200px;
      height: 40px;
    }
  }
}
</style>
