<template>
  <a-modal
    v-model="custTagsVisible"
    title="自定义指标"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="cust_tags_modal"
    :width="800"
  >
    <div class="cust-tags-container">
      <div class="cust-tag-left">
        <div class="cust-tag-left-header">
          <span>1.勾选需要显示的指标</span>
          <div class="left-main-header">
            <span class="header_btn" @click="handleCheckedAll">全选</span>
            <span>/</span>
            <span class="header_btn" @click="handleCheckedInverse">反选</span>
            <span>/</span>
            <span class="header_btn" @click="handleCheckedDefault">默认</span>
          </div>
        </div>
        <div class="left-main">
          <!-- <div class="left-main-header">
            <span class="header_btn" @click="handleCheckedAll">全选</span>
            <span>/</span>
            <span class="header_btn" @click="handleCheckedInverse">反选</span>
            <span>/</span>
            <span class="header_btn" @click="handleCheckedDefault">默认</span>
          </div> -->
          <div class="left-main-tags">
            <div v-if="showTitle">
              <h3>{{ title }}统计数据</h3>
            </div>
            <div class="checkbox">
              <template v-for="(e, i) in newTargetArr">
                <div v-if="e.type === title" :key="i" class="checkbox_item">
                  <label
                    :class="e.checked ? 'active' : ''"
                    :for="i"
                  ><input
                    :id="i"
                    type="checkbox"
                    :checked="e.checked"
                    :label="e.label"
                    @change="handelChecked(e, i)" />{{ e.label
                    }}<m-tips
                      v-if="e.desc"
                      :content="e.desc ? e.desc : ''"
                      :title="''"
                      :width="200"
                    /></label>
                </div>
              </template>
            </div>
            <div v-if="showTitle"><h3>API数据</h3></div>
            <div class="checkbox">
              <template v-for="(e, i) in newTargetArr">
                <div v-if="e.type === 'API'" :key="i" class="checkbox_item">
                  <label
                    :class="e.checked ? 'active' : ''"
                    :for="i"
                  ><input
                    :id="i"
                    type="checkbox"
                    :checked="e.checked"
                    :label="e.label"
                    @change="handelChecked(e, i)" />{{ e.label
                    }}<m-tips
                      v-if="e.desc"
                      :content="e.desc ? e.desc : ''"
                      :title="''"
                      :width="200"
                    /></label>
                </div>
              </template>
            </div>
            <div v-if="showTitle"><h3>数据GAP</h3></div>
            <div class="checkbox">
              <template v-for="(e, i) in newTargetArr">
                <div v-if="e.type === 'GAP'" :key="i" class="checkbox_item">
                  <label
                    :class="e.checked ? 'active' : ''"
                    :for="i"
                  ><input
                    :id="i"
                    type="checkbox"
                    :checked="e.checked"
                    :label="e.label"
                    @change="handelChecked(e, i)" />{{ e.label
                    }}<m-tips
                      v-if="e.desc"
                      :content="e.desc ? e.desc : ''"
                      :title="''"
                      :width="200"
                    /></label>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="cust-tag-right">
        <div class="cust-tag-right-header">
          <span>2. 拖动调整指标显示顺序</span>
        </div>
        <m-vuedraggable v-model="dragArr" animation="350" chosen-class="chosen" class="drag_box">
          <transition-group>
            <div v-for="(e, i) in dragArr" :key="e.prop" class="drag_item">
              <span class="el-icon-rank" />
              <span class="drag_text">{{ e.label }}</span>
              <span class="el-icon-close" @click="closeChecked(e, i)" />
            </div>
          </transition-group>
        </m-vuedraggable>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      newTargetArr: [],
      dragArr: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    targetArr: {
      default: () => [],
      type: Array
    },
    storageName: {
      default: '',
      type: String
    },
    sureArr: {
      default: () => [],
      type: Array
    },
    showTitle: {
      default: true,
      type: Boolean
    },
    hasCheckNum: {
      default: true,
      type: Boolean
    }
  },
  watch: {
    targetArr: {
      handler (val) {
        this.newTargetArr = JSON.parse(JSON.stringify(val))
        // this.dragArr = this.targetArr.filter((item) => item.checked)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.dragArr = JSON.parse(JSON.stringify(this.sureArr))
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    }),
    custTagsVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCheckedDefault () {
      this.newTargetArr.forEach((item) => {
        item.checked = item.default
      })
      this.dragArr = this.newTargetArr.filter((item) => {
        return item.checked
      })
    },
    handleCheckedInverse () {
      this.newTargetArr.forEach((item) => {
        item.checked = !item.checked
      })
      this.dragArr = this.newTargetArr.filter((item) => {
        return item.checked
      })
    },
    handleCheckedAll () {
      if (this.dragArr.length !== this.newTargetArr.length) {
        this.newTargetArr.forEach((item) => {
          if (!item.checked) {
            item.checked = true
            this.dragArr.push(item)
          }
        })
      } else {
        this.newTargetArr.forEach((item) => {
          item.checked = false
        })
        this.dragArr = []
      }
    },
    closeChecked (e, i) {
      this.dragArr.splice(i, 1)
      this.newTargetArr.forEach((item) => {
        if (item.prop === e.prop) {
          item.checked = false
        }
      })
    },
    handelChecked (val, i) {
      this.newTargetArr[i].checked = !this.newTargetArr[i].checked
      const isCheck = val.checked
      if (isCheck) {
        this.dragArr.push(this.newTargetArr[i])
      } else {
        this.dragArr = this.dragArr.filter((item) => item.prop !== val.prop)
      }
    },
    handleCancel () {
      this.custTagsVisible = false
    },
    handleSubmit () {
      if (this.dragArr.length < 5 && this.hasCheckNum) {
        return this.$message.warning('至少选中五个指标')
      }
      const sureIds = this.dragArr.map((item) => item.prop)
      const othersArr = this.newTargetArr.filter((item) => !sureIds.includes(item.prop))
      this.newTargetArr = [...this.dragArr, ...othersArr]
      localStorage.setItem(this.storageName, JSON.stringify(this.newTargetArr))
      this.$emit('changeCustTags', this.newTargetArr, this.dragArr)
      this.custTagsVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
input[type="checkbox"]{
  border: 1px solid;
}
.cust-tags-container {
  position: relative;
  border-radius: 5px;
  .cust-tag-left,
  .cust-tag-right {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  .cust-tag-left {
    width: 70%;
    border-radius: 5px;
    margin-right: 10px;
    min-height: 30vh;
    overflow-y: auto;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .left-main {
      flex: 1;
      display: flex;
      flex-direction: column;

      .left-main-tags {
        flex: 1;
        h3 {
          padding-left: 10px;
        }
        .checkbox {
          width: 100%;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          padding: 0 40px;
          margin-bottom: 20px;
          .checkbox_item {
            width: 33%;
            height: 40px;
            line-height: 40px;
            input {
              cursor: pointer;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
  .cust-tag-right {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(30% - 10px);
    border-radius: 5px;
    display: flex;
    height: 100%;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    .drag_box {
      flex: 1;
      background-color: #fff !important;
      overflow: auto;
      height: 100%;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .drag_item {
      display: flex;
      align-items: center;
      width: 92%;
      height: 35px;
      margin: 0 auto;
      background-color: #fff;
      border: 1px solid #dddfe6;
      box-shadow: 0 2px 2px 0 #dddfe6;
      margin-top: 9px;
      .el-icon-rank {
        width: 50px;
        text-align: center;
        font-size: 22px;
        cursor: move;
      }
      .drag_text {
        flex: 1;
        text-align: center;
      }
      .el-icon-close {
        font-size: 18px;
        width: 42px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.cust-tag-left-header,
.cust-tag-right-header {
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  .left-main-header {
    height: 40px;
    display: flex;
    flex-direction: row-reverse;
    color: @primary-color;
    line-height: 40px;
    .header_btn {
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
.cust-tag-left-header {
  display: flex;
  justify-content: space-between;
}
</style>

<style lang="less">
.cust_tags_modal {
  .ant-modal-body {
    padding: 10px;
    background-color: #eee;
  }
}
</style>
