import store from '@/store'
const title = store.getters.title

export default {
  targetArr: [
    {
      title: `${title}统计数据`,
      list: [
        {
          label: '广告请求', // label
          sorter: true, // 是否排序
          width: 120, // 宽度
          prop: 'advRequest', // 属性
          align: 'left', // 对齐方式
          desc: `${title}向广告平台发起请求的次数`
        },
        {
          label: '广告填充',
          width: 120,
          prop: 'advResponse',
          align: 'left',
          type: title,
          desc: `${title}向广告平台发送请求后返回成功次数`,
          sorter: false
        },
        {
          label: '填充率',
          width: 130,
          prop: 'advRequestFilledRate',
          align: 'left',
          desc: `${title}向广告平台发送请求后返回成功的占比`,
          sorter: true
        },
        {
          label: '填充耗时（秒）',
          width: 150,
          prop: 'advResponseTime',
          align: 'left',
          desc: `${title} SDK向广告平台发送请求到填充成功的平均耗时`,
          sorter: false
        },
        {
          label: '展示',
          checked: true,
          width: 100,
          prop: 'impress',
          align: 'left',
          desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
          sorter: true
        },
        {
          label: '展示率',
          width: 130,
          prop: 'impressRate',
          align: 'left',
          type: title,
          sorter: true,
          desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`
        },
        {
          label: '消耗金额',
          width: 120,
          prop: 'cost',
          align: 'left',
          desc: `消耗金额=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
          sorter: true
        },
        {
          label: '点击率',
          width: 130,
          prop: 'clickRate',
          align: 'left',
          desc: `${title}统计的点击率，点击率=点击数/展示数`,
          sorter: true
        },
        {
          label: '竞价响应eCPM',
          width: 180,
          prop: 'askPrice',
          align: 'left',
          desc: `竞价广告平台在竞价响应时返回的平均eCPM`,
          sorter: true
        },
        {
          label: '竞价',
          width: 120,
          prop: 'askPriceRequest',
          align: 'left',
          sorter: false,
          desc: `${title}服务端向竞价广告平台发起的竞价次数；`
        },
        {
          label: '竞价响应率',
          width: 150,
          prop: 'askPriceFilledRate',
          align: 'left',
          sorter: true,
          desc: `竞价广告平台竞价响应率 = 竞价响应次数 / 竞价次数 * 100%`
        },
        {
          label: '竞价成功率',
          width: 150,
          prop: 'bidSuccessRate',
          align: 'left',
          sorter: false,
          desc: `-`
        },
        {
          label: '点击',
          width: 100,
          prop: 'click',
          align: 'left',
          sorter: true,
          desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`
        },
        {
          label: '展请率',
          width: 130,
          prop: 'impressRequestRate',
          align: 'left',
          type: title,
          desc: `${title}统计到的展示占${title}收到的请求比例；`,
          sorter: true
        },
        {
          label: 'CPC',
          width: 100,
          prop: 'cpc',
          align: 'left',
          desc: `预估收益/点击`,
          sorter: false
        },
        {
          label: '广告成交额',
          width: 150,
          prop: 'advPrice',
          align: 'left',
          desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
          sorter: true
        },
        {
          label: '预估eCPM',
          width: 150,
          prop: 'estimatedRevenueEcpm',
          align: 'left',
          desc: `（预估收益/${title}统计的展示）*1000`,
          sorter: true
        },
        {
          label: '利润率',
          width: 130,
          prop: 'profitRate',
          align: 'left',
          type: title,
          desc: `（成交额-消耗金额）/成交额*100%`,
          sorter: false
        },
        {
          label: '广告成交CPC',
          width: 160,
          prop: 'advPriceCpc',
          align: 'left',
          type: title,
          desc: `广告成交额/点击`,
          sorter: false
        }
      ]
    }
  ],
  sureArr: [
    {
      label: '广告请求', // label
      sorter: true, // 是否排序
      width: 140,
      prop: 'advRequest', // 属性
      align: 'left', // 对齐方式
      desc: `${title}向广告平台发起请求的次数`
    },
    {
      label: '填充率',
      width: 140,
      prop: 'advRequestFilledRate',
      align: 'left',
      sorter: true,
      desc: `${title}向广告平台发送请求后返回成功的占比`
    },
    {
      label: '填充耗时（秒）',
      width: 180,
      prop: 'advResponseTime',
      align: 'left',
      desc: `${title} SDK向广告平台发送请求到填充成功的平均耗时`,
      sorter: false
    },
    {
      label: '展示',
      prop: 'impress',
      width: 140,
      align: 'left',
      desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
      sorter: true
    },
    {
      label: '展示率',
      width: 140,
      prop: 'impressRate',
      align: 'left',
      type: title,
      desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`,
      sorter: true
    },
    {
      label: '消耗金额',
      width: 120,
      prop: 'cost',
      align: 'left',
      desc: `消耗金额=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
      sorter: true
    },
    {
      label: '广告成交额',
      width: 140,
      prop: 'advPrice',
      align: 'left',
      desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
      sorter: true
    }
  ]
}

export const styleList = [
  { id: '1', name: '开屏' },
  { id: '2', name: '插屏' },
  { id: '3', name: '激励视频' },
  { id: '4', name: '信息流' }
]
