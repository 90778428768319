<template>
  <div class="data-warning-container">
    <div class="menu_box">
      <div class="menu_title">数据预警</div>
      <!-- <a-menu mode="inline" v-model="selectedKeys" type="inner">
        <a-menu-item key="report">
          <span> 预警报表 </span>
        </a-menu-item>
        <a-menu-item key="rule">
          <span> 预警规则 </span>
        </a-menu-item>
      </a-menu> -->
      <a-radio-group v-model="selectedKeys" @change="selectMenuItem">
        <a-radio-button class="radioIsadmin" v-for="(item, inx) in radioArr" :key="inx" :value="item.value">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="warning_main">
      <WarningReport v-if="selectedKeys === 'report'" />
      <WarningRule v-if="selectedKeys === 'rule'" @handleEditWarning="handleEditWarning" />
      <WarningRuleEdit v-if="selectedKeys === 'edit'" @toRule="toRule" ref="edit" />
    </div>
  </div>
</template>

<script>
import WarningReport from './WarningReport'
import WarningRule from './WarningRule'
import WarningRuleEdit from './WarningRuleEdit'
export default {
  name: 'Datawarning',
  components: {
    WarningReport,
    WarningRule,
    WarningRuleEdit
  },
  data () {
    return {
      selectedKeys: 'report',
      radioArr: [
        { name: '预警报表', value: 'report' },
        { name: '预警规则', value: 'rule' }
      ]
    }
  },
  methods: {
    selectMenuItem () {},
    handleEditWarning ({ query, isAdd }) {
      this.selectedKeys = 'edit'
      this.$nextTick(() => {
        this.$refs.edit.dealquery(query, isAdd)
      })
    },
    toRule () {
      this.selectedKeys = 'rule'
    }
  }
}
</script>

<style lang="less">
@import '~@/assets/less/mixins/redefineAnt.less';
.data-warning-container {
  margin: 0 10px 10px 10px;
  min-height: 90vh;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  .menu_box {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .menu_title {
      width: 70px;
      font-size: 15px;
      line-height: 32px;
      font-weight: 500;
    }
    .ant-menu.ant-menu-inline {
      border-radius: 0 0 10px 10px;
      .ant-menu-item {
        border-left: 3px solid #fff;
        margin: 0;
      }
      .ant-menu-item.ant-menu-item-selected {
        border-left: 3px solid @primary-color;
      }
      .ant-menu-item::after {
        border-right: none;
      }
    }
  }
  .warning_main {
    position: relative;
    flex: 1;
  }
  .aRadioBtn();
}
</style>
